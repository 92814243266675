<template>
  <header class="desktop-header">
    <ul class="navigation-list">
      <li class="navigation-list-item">
        <a
          href="#"
          :class="currentComponent == 'About' ? 'selected' : ''"
          @click="currentComponent = 'About'"
        >
          {{ currentLanguage == 'EN' ? 'About' : 'Chi sono' }}
        </a>
      </li>
      <li class="navigation-list-item">
        <a
          href="#"
          :class="currentComponent == 'Skills' ? 'selected' : ''"
          @click="currentComponent = 'Skills'"
        >
          {{ currentLanguage == 'EN' ? 'Skills' : 'Competenze' }}
        </a>
      </li>
      <li class="navigation-list-item" @click="currentComponent = 'Resume'">
        <a
          href="#"
          :class="currentComponent == 'Resume' ? 'selected' : ''"
          @click="currentComponent = 'Resume'"
        >
          {{ currentLanguage == 'EN' ? 'Resume' : 'Curriculum Vitae' }}
        </a>
      </li>
    </ul>
    <b-dropdown variant="link" toggle-class="desktop-header-dropdown-toggle">
      <template #button-content>
        <img :src="`flags/${currentLanguage}.jpg`" height="13" class="nations-flags">
        {{ currentLanguage }}
      </template>
      <div
        v-for="(language, index) in languageChoices"
        :key="language"
      >
        <b-dropdown-item @click="$store.commit('changeLanguage', language)">
          <img :src="`flags/${language}.jpg`" height="13" class="nations-flags">
          {{ language }}
        </b-dropdown-item>
        <b-dropdown-divider v-if="index < languageChoices.length - 1" />
      </div>
    </b-dropdown>
  </header>
</template>

<script>
export default {
  name: 'DesktopHeader',
  computed: {
    languageChoices() {
      return this.$store.state.availableLanguages.filter(
        language => language != this.currentLanguage
      )
    },
    currentComponent: {
      get() {
        return this.$store.state.currentComponent
      },
      set(component) {
        this.$store.commit('changeComponent', component)
      }
    },
    currentLanguage() {
      return this.$store.state.currentLanguage
    }
  }
}
</script>

<style>

</style>
<template>
  <section id="resume">
    <section v-if="currentLanguage == 'EN'">
      <section id="experiences">
        <h4>Work Experience</h4>
        <div class="resume-item">
          <h5>
            2022 - present, Software Engineer
            <a href="https://www.modula.eu" target="_blank">@Modula S.p.A.</a>
          </h5>
          <ul class="p-0">
            <div class="experience-description">
              <i class="fa-solid fa-caret-right" />
              <li>
                Development of a warehouse management software <b>(Modula WMS)</b> to support operational logistics
              </li>
            </div>
            <div class="experience-description">
              <i class="fa-solid fa-caret-right" />
              <li>
                Development of direct interfaces with company <b>ERP</b> systems
              </li>
            </div>
            <div class="experience-description">
              <i class="fa-solid fa-caret-right" />
              <li>
                Technical support for the installations and configurations of Modula's vertical warehouses
              </li>
            </div>
          </ul>
        </div>
        <div class="resume-item">
          <h5>
            2020 - 2022, Full Stack Developer
            <a href="https://www.linkedin.com/company/edilgo/" target="_blank">@EdilGo</a>
          </h5>
          <ul class="p-0">
            <div class="experience-description">
              <i class="fa-solid fa-caret-right" />
              <li>
                Development and maintenance (unit testing and end-to-end testing)
                of back-end using <b>Django</b> and <b>Graphene</b>
              </li>
            </div>
            <div class="experience-description">
              <i class="fa-solid fa-caret-right" />
              <li>
                Front-end development using <b>Vue.js</b> (HTML, CSS, Javascript, Bootstrap)
              </li>
            </div>
            <div class="experience-description">
              <i class="fa-solid fa-caret-right" />
              <li>
                Development of API and microservices using Python (<b>Flask</b>, <b>FastAPI</b>)
                and Golang (<b>Gin</b>)
              </li>
            </div>
            <div class="experience-description">
              <i class="fa-solid fa-caret-right" />
              <li>
                Servers and database management using <b>Docker</b> and <b>Kubernetes</b>
              </li>
            </div>
          </ul>
        </div>
        <div class="resume-item">
          <h5>
            2017, Electrician internship
            <a href="https://www.microlog.it" target="_blank">@Microlog</a>
          </h5>
          <ul class="p-0">
            <div class="experience-description">
              <i class="fa-solid fa-caret-right" />
              <li>Creation, implementation and beta testing of industrial installations</li>
            </div>
          </ul>
        </div>
      </section>
      <section id="education">
        <h4>Education</h4>
        <div class="resume-item">
          <h5 class="mb-0">
            2018 - retired, Computer Engineering
          </h5>
          <div style="font-size: 16px; font-weight: 500; margin-bottom: .5rem;">
            Università degli Studi di Modena e Reggio Emilia, Modena
          </div>
          <p style="font-size: 15px;">
            During the course I had the opportunity to join a small startup, and a few months later,
            seeing the rapid progress achieved in the practical field, I decided to focus everything
            on work experiences.
          </p>
        </div>
        <div class="resume-item">
          <h5 class="mb-0">
            2017, English language course
          </h5>
          <div style="font-size: 16px; font-weight: 500; margin-bottom: .5rem;">
            Cambridge Assessment English
          </div>
        </div>
        <div class="resume-item">
          <h5 class="mb-0">
            2013 - 2018, Computer Expert
          </h5>
          <div style="font-size: 16px; font-weight: 500; margin-bottom: .5rem;">
            Istituto di Istruzione Superiore A. Volta, Sassuolo
          </div>
        </div>
      </section>
    </section>
    <section v-else-if="currentLanguage == 'IT'">
      <section id="experiences">
        <h4>Esperienze Lavorative</h4>
        <div class="resume-item">
          <h5>
            2022 - in corso, Software Engineer
            <a href="https://www.modula.eu" target="_blank">@Modula S.p.A.</a>
          </h5>
          <ul class="p-0">
            <div class="experience-description">
              <i class="fa-solid fa-caret-right" />
              <li>
                Sviluppo di un software di gestione dei magazzini (<b>Modula WMS</b>) di supporto alla logistica operativa
              </li>
            </div>
            <div class="experience-description">
              <i class="fa-solid fa-caret-right" />
              <li>
                Sviluppo di interfacce dirette con i sistemi <b>ERP</b> aziendali
              </li>
            </div>
            <div class="experience-description">
              <i class="fa-solid fa-caret-right" />
              <li>
                Supporto tecnico alle installazioni e alle configurazioni dei magazzini verticali di Modula
              </li>
            </div>
          </ul>
        </div>
        <div class="resume-item">
          <h5>
            2020 - 2022, Full Stack Developer
            <a href="https://www.linkedin.com/company/edilgo/" target="_blank">@EdilGo</a>
          </h5>
          <ul class="p-0">
            <div class="experience-description">
              <i class="fa-solid fa-caret-right" />
              <li>
                Sviluppo e mantenimento (unit testing e end-to-end testing)
                back-end utilizzando <b>Django</b> e <b>Graphene</b>
              </li>
            </div>
            <div class="experience-description">
              <i class="fa-solid fa-caret-right" />
              <li>
                Sviluppo front-end utilizzando <b>Vue.js</b> (HTML, CSS, Javascript, Bootstrap)
              </li>
            </div>
            <div class="experience-description">
              <i class="fa-solid fa-caret-right" />
              <li>
                Sviluppo di API e microservizi utilizzando Python (<b>Flask</b>, <b>FastAPI</b>)
                e Golang (<b>Gin</b>)
              </li>
            </div>
            <div class="experience-description">
              <i class="fa-solid fa-caret-right" />
              <li>
                Gestione server e database utilizzando <b>Docker</b> e <b>Kubernetes</b>
              </li>
            </div>
          </ul>
        </div>
        <div class="resume-item">
          <h5>
            2017, Stage Elettricista
            <a href="https://www.microlog.it" target="_blank">@Microlog</a>
          </h5>
          <ul class="p-0">
            <div class="experience-description">
              <i class="fa-solid fa-caret-right" />
              <li>Creazione, implementazione e beta testing di impianti industriali</li>
            </div>
          </ul>
        </div>
      </section>
      <section id="education">
        <h4>Istruzione e formazione</h4>
        <div class="resume-item">
          <h5 class="mb-0">
            2018 - ritirato, Ingegneria Informatica
          </h5>
          <div style="font-size: 16px; font-weight: 500; margin-bottom: .5rem;">
            Università degli Studi di Modena e Reggio Emilia, Modena
          </div>
          <p style="font-size: 13px;">
            Durante il corso ho avuto l'opportunità di entrare in una piccola startup,
            e qualche mese dopo, vedendo i rapidi progressi conseguiti nell'ambito pratico,
            ho deciso di puntare tutto sulle esperienze lavorative.
          </p>
        </div>
        <div class="resume-item">
          <h5 class="mb-0">
            2017, corso di lingua Inglese
          </h5>
          <div style="font-size: 16px; font-weight: 500; margin-bottom: .5rem;">
            Cambridge Assessment English
          </div>
        </div>
        <div class="resume-item">
          <h5 class="mb-0">
            2013 - 2018, Perito Informatico
          </h5>
          <div style="font-size: 16px; font-weight: 500; margin-bottom: .5rem;">
            Istituto di Istruzione Superiore A. Volta, Sassuolo
          </div>
        </div>
      </section>
    </section>
  </section>
</template>

<script>
export default {
  name: 'ResumeContent',
  computed: {
    currentLanguage() {
      return this.$store.state.currentLanguage
    }
  }
}
</script>

<style>

</style>
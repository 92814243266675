<template>
  <div id="profile">
    <img src="@/assets/images/profile_icon.png" width="128" class="profile-picture">
    <h1 class="mt-4">
      Cristian Vignoli
    </h1>
    <h5 class="mb-4">
      Full Stack Developer
    </h5>
    <div class="links-icons">
      <a href="https://linkedin.com/in/cristian-vignoli/" target="_blank">
        <i class="fa-brands fa-linkedin" />
      </a>
      <a href="https://instagram.com/holyvigno" target="_blank">
        <i class="fa-brands fa-instagram" />
      </a>
      <a href="mailto:cristianvignoli99@gmail.com" target="_blank">
        <i class="fa-solid fa-envelope" />
      </a>
    </div>
  </div>
  <section class="copyright">
    © Cristian Vignoli 2022
  </section>
</template>

<script>
export default {
  name: 'ProfileSection'
}
</script>

<style>

</style>
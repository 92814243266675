import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import BootstrapVue3 from 'bootstrap-vue-3'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import "@/assets/css/style.css"

const store = createStore({
  state () {
    return {
      availableLanguages: ['IT', 'EN'],
      currentLanguage: 'IT',
      currentComponent: 'About'
    }
  },
  mutations: {
    changeLanguage: (state, language) => state.currentLanguage = language,
    changeComponent: (state, component) => state.currentComponent = component
  }
})

const app = createApp(App)
app.use(BootstrapVue3)
app.use(store)
app.mount('#app')
<template>
  <section v-if="currentLanguage == 'EN'" id="about">
    <section id="introduction" class="mb-5">
      <h4>Who am I?</h4>
      <p>
        Hi! My name is Cristian and I am Full Stack Developer.
        In 2019 I approached web development, and my life has changed radically: I am very passionate
        about creating something that can <b>solve</b> people's and companies' <b>problems</b>!
      </p>
      <p>
        Initially, it all started as a "hobby" while I was attending the Computer Engineering course
        at the University of Modena and Reggio Emilia, but seeing how my skills were in the practical field
        growing so quickly, I decided to dedicate myself 100% to work activities.
      </p>
    </section>
    <section id="features">
      <h4>My main features</h4>
      <p>
        Personally, I believe that the best way to do a great work is to
        <b>always expect the best of yourself</b>. For this reason, for every activity I perform,
        from defining the architecture of a software to the single line of code, I set for me and for the
        team I work in <b>high standards</b>.
      </p>
      <p>
        Speaking of teams ... I think that <b>collaboration</b> with other people is very important
        to accomplish <b>quality work</b> on a large scale. Working in a positive environment
        can only benefit everyone: for this reason I consider team building activities
        to be very important, and I am always excited to participate!
      </p>
      <p>
        As perhaps it could be deduced, I love to test myself. Having excellent <b>problem solving</b>
        skills, I am not afraid to work under pressure: in fact, I can probably give my best in those situations.
      </p>
      <p>
        I am a very <b>precise</b> and <b>methodical</b> person: I meticulously plan the activities to be
        developed and I pay particular <b>attention to details</b>.
      </p>
    </section>
  </section>
  <section v-else-if="currentLanguage == 'IT'" id="about">
    <section id="introduction" class="mb-5">
      <h4>Chi sono?</h4>
      <p>
        Ciao! Mi chiamo Cristian e sono un Full Stack Developer.
        Nel 2019 mi sono avvicinato allo sviluppo web, e la mia vita è cambiata radicalmente: creare
        qualcosa che possa <b>risolvere i problemi</b> delle persone e delle aziende mi appassiona molto!
      </p>
      <p>
        Inizialmente, tutto è partito come "hobby" mentre frequentavo il corso di Ingegneria Informatica
        presso l'Università di Modena e Reggio Emilia, ma vedendo come le mie capacità in ambito pratico
        stessero crescendo così rapidamente, ho deciso di dedicarmi al 100% alle attività lavorative.
      </p>
    </section>
    <section id="features">
      <h4>Le mie principali caratteristiche</h4>
      <p>
        Personalmente, credo che il miglior modo per compiere un ottimo lavoro sia
        <b>pretendere sempre il massimo da se stessi</b>. Per questo motivo per ogni attività che svolgo,
        dal definire l'architettura di un software alla singola riga di codice, fisso per me e per il
        team in cui lavoro degli <b>standard alti</b>.
      </p>
      <p>
        Parlando di team... Penso che la <b>collaborazione</b> con altre persone sia molto importante per
        portare a termine un <b>lavoro di qualità</b> su larga scala. Lavorare in un ambiente positivo
        non può che essere un beneficio per tutti: per questo motivo ritengo le attività di
        team building molto importanti, e sono sempre entusiasta di parteciparvi!
      </p>
      <p>
        Come forse si è potuto evincere, adoro mettermi alla prova. Avendo ottime abilità nel
        <b>problem solving</b>, non temo di lavorare sotto pressione, anzi, riesco probabilmente
        a dare il meglio di me in quelle situazioni.
      </p>
      <p>
        Sono una persona molto <b>precisa</b> e <b>metodica</b>: pianifico meticolosamente le attività da
        svolgere e presto particolare <b>attenzione ai dettagli</b>.
      </p>
    </section>
  </section>
</template>

<script>
export default {
  name: 'AboutComponent',
  computed: {
    currentLanguage() {
      return this.$store.state.currentLanguage
    }
  }
}
</script>

<style>

</style>
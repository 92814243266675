<template>
  <b-row class="content-container">
    <mobile-header />
    <b-col id="profile-container" class="col-12 col-lg-4">
      <profile />
    </b-col>
    <b-col id="main-container" class="col-12 col-lg-8">
      <main-content />
    </b-col>
  </b-row>
</template>

<script>
import Profile from '@/components/Profile'
import MainContent from '@/components/MainContent'
import MobileHeader from '@/components/headers/MobileHeader'

export default {
  name: 'App',
  components: {
    Profile,
    MainContent,
    MobileHeader
  }
}
</script>

<style>

</style>
<template>
  <desktop-header />
  <div class="main-content">
    <component :is="currentComponent" />
    <section class="copyright">
      © Cristian Vignoli 2022
    </section>
  </div>
</template>

<script>
import DesktopHeader from '@/components/headers/DesktopHeader'
import About from '@/components/main-content/About'
import Skills from '@/components/main-content/Skills'
import Resume from '@/components/main-content/Resume'

export default {
  name: 'MainContent',
  components: {
    DesktopHeader,
    About,
    Skills,
    Resume
  },
  computed: {
    currentComponent() {
      return this.$store.state.currentComponent
    }
  }
}
</script>

<style>

</style>
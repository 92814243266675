<template>
  <section id="skills">
    <section v-if="currentLanguage == 'EN'" id="introduction">
      <h4>Main skills</h4>
      <p>
        Below, a brief summary of the skills I have been able to best develop during
        my recent work experiences.
      </p>
    </section>
    <section v-else-if="currentLanguage == 'IT'" id="introduction">
      <h4>Principali competenze</h4>
      <p>
        Di seguito, un breve riassunto delle competenze che ho potuto sviluppare meglio durante
        le mie ultime esperienze lavorative.
      </p>
    </section>
    <section id="skill-bars" style="font-size: 14px;">
      <b-row>
        <skill name="Python" :level="95" align="left" />
        <skill name="Django" :level="95" align="right" />
      </b-row>
      <b-row>
        <skill name="Flask" :level="85" align="left" />
        <skill name="PostgreSQL" :level="80" align="right" />
      </b-row>
      <b-row>
        <skill name="Javascript" :level="95" align="left" />
        <skill name="Vue.js" :level="95" align="right" />
      </b-row>
      <b-row>
        <skill name="HTML" :level="95" align="left" />
        <skill name="CSS" :level="95" align="right" />
      </b-row>
      <b-row>
        <skill name="Bootstrap" :level="90" align="left" />
        <skill name="Git" :level="100" align="right" />
      </b-row>
      <b-row>
        <skill name="Docker" :level="75" align="left" />
        <skill name="Kubernetes" :level="70" align="right" />
      </b-row>
    </section>
    <section v-if="currentLanguage == 'EN'" id="details">
      <h4>Let's deepen...</h4>
      <p>
        Personally, I find <b>Django</b> + <b>Graphene</b> for backend management and
        <b>Vue.js</b> for frontend management a great stack, and I'd like to work with
        these frameworks again.
      </p>
      <p>
        However, I'm a <b>fast learner</b> and I am open to delve into other types of technologies.
        New challenges ignite me, and I can't wait to try my hand at projects that use
        some of these that I don't know in detail yet!
      </p>
      <p>
        During university I also experimented other technologies, such as C, C++, Assembly
        or SQL. These were not been mentioned in the graph because I didn't have the opportunity
        to apply them in a real professional project.
      </p>
    </section>
    <section v-else-if="currentLanguage == 'IT'" id="details">
      <h4>Approfondiamo...</h4>
      <p>
        Personalmente, trovo <b>Django</b> + <b>Graphene</b> per la gestione backend e
        <b>Vue.js</b> per la gestione frontend un ottimo stack, e mi piacerebbe lavorare
        ancora con questi frameworks.
      </p>
      <p>
        Tuttavia, <b>imparo velocemente</b> e sono aperto ad approfondire altri tipi di tecnologie.
        Le nuove sfide mi accendono, e non vedo l'ora di potermi cimentare in progetti che utilizzano
        alcune di queste che non conosco ancora nel dettaglio!
      </p>
      <p>
        Durante l'università ho sperimentato anche altre tecnologie, come ad esempio
        C, C++, Assembly o SQL. Queste non sono state citate nel grafico perchè non
        ho avuto l'occasione applicarle in un progetto lavorativo reale.
      </p>
    </section>
  </section>
</template>

<script>
import Skill from '@/components/Skill'

export default {
  name: 'SkillsContent',
  components: {
    Skill
  },
  computed: {
    currentLanguage() {
      return this.$store.state.currentLanguage
    }
  }
}
</script>

<style>

</style>
<template>
  <header class="mobile-header">
    <b-dropdown variant="link" toggle-class="mobile-header-dropdown-toggle" no-caret>
      <template #button-content>
        <i class="fa-solid fa-bars" />
      </template>
      <ul class="mobile-menu">
        <li class="mobile-list-item">
          <a
            href="#"
            :class="currentComponent == 'About' ? 'selected' : ''"
            @click="currentComponent = 'About'"
          >
            {{ currentLanguage == 'EN' ? 'About' : 'Chi sono' }}
          </a>
        </li>
        <li class="mobile-list-item">
          <a
            href="#"
            :class="currentComponent == 'Skills' ? 'selected' : ''"
            @click="currentComponent = 'Skills'"
          >
            {{ currentLanguage == 'EN' ? 'Skills' : 'Competenze' }}
          </a>
        </li>
        <li class="mobile-list-item" @click="currentComponent = 'Resume'">
          <a
            href="#"
            :class="currentComponent == 'Resume' ? 'selected' : ''"
            @click="currentComponent = 'Resume'"
          >
            {{ currentLanguage == 'EN' ? 'Resume' : 'Curriculum Vitae' }}
          </a>
        </li>
      </ul>
    </b-dropdown>
    <b-dropdown class="language" variant="link" toggle-class="mobile-header-dropdown-toggle">
      <template #button-content>
        <img :src="`flags/${currentLanguage}.jpg`" height="13" class="nations-flags">
        {{ currentLanguage }}
      </template>
      <div
        v-for="(language, index) in languageChoices"
        :key="language"
      >
        <b-dropdown-item @click="$store.commit('changeLanguage', language)">
          <img :src="`flags/${language}.jpg`" height="13" class="nations-flags">
          {{ language }}
        </b-dropdown-item>
        <b-dropdown-divider v-if="index < languageChoices.length - 1" />
      </div>
    </b-dropdown>
  </header>
</template>

<script>
export default {
  name: 'MobileHeader',
  computed: {
    languageChoices() {
      return this.$store.state.availableLanguages.filter(
        language => language != this.currentLanguage
      )
    },
    currentLanguage() {
      return this.$store.state.currentLanguage
    },
    currentComponent: {
      get() {
        return this.$store.state.currentComponent
      },
      set(component) {
        this.$store.commit('changeComponent', component)
      }
    }
  }
}
</script>

<style>

</style>
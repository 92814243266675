<template>
  <b-col class="col-12 col-xl-6" :class="`skill-${align}`">
    <div class="d-flex justify-content-between align-items-center">
      <b>{{ name }}</b>
      <b>{{ level }}%</b>
    </div>
    <b-progress :value="level" style="height: .5rem;" />
  </b-col>
</template>

<script>
export default {
  name: 'SingleSkill',
  props: {
    name: {
      type: String,
      default: ''
    },
    level: {
      type: Number,
      default: 0
    },
    align: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>

</style>